<template>
  <div class="add-new-customer">
    <title-bar
      submitBtn
      submitBtnTitle="Save settings"
      title="Settings"
      @submitPressed="submitPressed"
    />
    <employee-form v-if="user" entity="Settings" :requiredRole="false" :userObject="user" :action="action" @clearAction="action = null" />
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar.vue'
import EmployeeForm from '../Employees/components/EmployeeForm.vue'
import {getUserData} from "@/auth/utils"

export default {
  components: {
    TitleBar,
    EmployeeForm
  },
  data() {
    return {
      action: null,
      user: null
    }
  },
  created() {
    this.load()
  },
  methods: {
    load(){
      this.$Users.getResource({ id: getUserData().id }).then(response => {
        this.user = response.data
      })
    },
    submitPressed() {
      this.action = 'update'
    }
  },
}
</script>
