<template>
  <validation-observer ref="courierForm" tag="form" @submit.prevent="onSubmit">
    <image-upload :preview-image="fileURL" @fileUploaded="setPhoto" />
    <b-form>
      <b-row>
        <b-col cols="12">
          <b-form-group
            class="row"
            label="First name"
            label-for="h-first-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="First name"
              vid="user.givenName"
            >
              <b-form-input
                v-model="user.givenName"
                id="h-first-name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Last name"
            label-for="h-last-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Last name"
              vid="user.familyName"
            >
              <b-form-input
                v-model="user.familyName"
                id="h-last-name"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Additional name"
            label-for="h-additional-name"
            label-cols-md="2"
            content-cols-lg="4"
          >
              <b-form-input
                v-model="user.additionalName"
                id="h-first-name"
              />
          </b-form-group>

          <b-form-group
            class="row"
            label="Phone"
            label-for="h-phone"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Phone"
              vid="user.telephone"
            >
              <b-form-input
                id="h-phone"
                v-model="user.telephone"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            label="Email"
            label-for="h-email"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required|email"
              name="Email"
              vid="user.email"
            >
              <b-form-input
                id="h-email"
                type="email"
                v-model="user.email"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            v-if="requiredRole"
            class="row"
            label="Role"
            label-for="h-role"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              rules="required"
              name="Role"
              vid="user.role"
            >
              <custom-select v-model="user.role" :options="roles" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <b-form-group
            class="row"
            :label="requiredPassword ? 'Password' : 'Change password'"
            label-for="h-password"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <validation-provider
              #default="{ errors }"
              :rules="requiredPassword ? 'required' : ''"
              name="Password"
              vid="user.password"
            >
              <b-form-input
                id="h-password"
                type="password"
                v-model="user.password"
                :state="errors.length > 0 ? false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            class="row"
            label="Enabled"
            label-for="h-active"
            label-cols-md="2"
            content-cols-lg="4"
          >
            <b-form-checkbox v-model="user.enabled" id="h-active" switch />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import CustomSelect from '@/components/Inputs/CustomSelect.vue'
import Toast from '@/components/Toast.vue'
import ImageUpload from '@/components/Inputs/ImageUpload.vue'

export default {
  components: {
    ImageUpload,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    CustomSelect,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    userObject: { Object, required: true },
    action: { type: String, default: null },
    requiredPassword: { type: Boolean, default: false }, // password je required iba pri add, nie update
    requiredRole: { type: Boolean, default: false },
    redirect: { type: String, default: null },
    entity: { type: String, default: null },
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      user: this.userObject,
      fileURL: null,
      enabledRoles: ['ROLE_STOCK_MANAGER', 'ROLE_DISPATCHER'],
      roles: [],
    }
  },
  watch: {
    action(newVal) {
      if (newVal === 'add') {
        this.onSubmit()
      }
      if (newVal === 'update') {
        this.onSubmit()
      }
    },
  },
  created() {
    if (this.userObject.fileUrl) {
      this.fileURL = this.userObject.fileUrl
    }

    if (this.requiredRole) {
      this.enabledRoles.forEach(role => {
        this.roles.push({
          label: this.$helper.getEnumTranslation('roles', role),
          id: role,
        })
      })
    }

    if (this.user.role) {
      this.user.role = {
        label: this.$helper.getEnumTranslation('roles', this.user.role),
        id: this.user.role,
      }
    }
  },
  methods: {
    setPhoto(data) {
      this.user.file = data['@id']
      this.fileURL = data.url
    },
    async onSubmit() {
      const isValid = await this.$refs.courierForm.validate()
      if (isValid) {
        if (this.user.role) {
          this.user.role = this.user.role.id
        }
        if (this.action === 'add') {
          this.$Users
            .createResource({ body: this.user })
            .then(response => {
              if (response.status === 201) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  `New ${this.entity.toLowerCase()} created`,
                  '',
                  'success',
                )
                if (this.redirect) {
                  this.$router.push(this.redirect)
                }
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  `Failed to create new ${this.entity.toLowerCase()}`,
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }

        if (this.action === 'update') {
          this.$Users
            .updateResource({ id: this.user.id, body: this.user })
            .then(response => {
              if (response.status === 200) {
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  `${this.entity} updated`,
                  '',
                  'success',
                )
                if (this.redirect) {
                  this.$router.push(this.redirect)
                }
              }
            })
            .catch(error => {
              if (error.response) {
                this.$emit('clearAction')
                this.$helper.showToast(
                  this.$toast,
                  Toast,
                  `Failed to update ${this.entity.toLowerCase()}`,
                  error.response.data['hydra:description'],
                  'danger',
                )
              }
            })
        }
      } else {
        this.$emit('clearAction')
      }
    },
  },
}
</script>
