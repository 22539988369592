<template>
  <div class="file-input-component-wrap">
    <div class="upload-photo">
      <b-overlay :show="loading" rounded="sm" class="loading">
        <b-avatar variant="primary" class="add-photo-img">
          <img
            v-if="mutablePreviewImage == ''"
            class="upload-placeholder"
            src="@/assets/images/icons/add-photo.svg"
            alt="upload-placeholder"
          />
          <img
            v-if="mutablePreviewImage != ''"
            class="file-input-component-image-preview w-full h-auto"
            :src="mutablePreviewImage"
            alt="image-preview"
          />
        </b-avatar>
      </b-overlay>
      <div v-if="!forUserSettings" class="edit-badge" @click="triggerInput">
        <img src="@/assets/images/icons/edit.svg" alt="" />
      </div>
    </div>

    <div v-if="!forUserSettings" class="file-input-component hidden">
      <input
        ref="fileInput"
        class="file-input"
        type="file"
        :accept="accept"
        @input="pickFile"
      />
    </div>
  </div>
</template>

<script>
import { BOverlay, BAvatar } from 'bootstrap-vue'
import Toast from '@/components/Toast.vue'

export default {
  name: 'ImageUpload',
  components: { BOverlay, BAvatar },
  props: {
    previewImage: { type: String, default: null },
    accept: { type: String, default: 'image/*' },
    forUserSettings: { type: Boolean, default: false },
  },
  data() {
    return {
      apiUrl: this.$store.state.apiUrl,
      mutablePreviewImage: [],
      loading: false,
    }
  },
  created() {
    if (this.previewImage && this.accept === 'image/*') {
      this.mutablePreviewImage.push(this.previewImage)
    }
  },
  methods: {
    triggerInput() {
      this.$refs.fileInput.click()
    },
    pickFile() {
      const input = this.$refs.fileInput
      const file = input.files
      const promises = []

      if (file && file[0]) {
        this.loading = true
        for (let i = 0; i < file.length; i += 1) {
          const reader = new FileReader()
          reader.onload = e => {
            this.mutablePreviewImage = []
            if (this.accept === 'image/*') {
              this.mutablePreviewImage.push(e.target.result)
            }
          }
          reader.readAsDataURL(file[i])
          this.$emit('input', file[i])
          promises.push(this.fileUpload(file[i]))
        }
        Promise.all(promises).then(results => {
          if (results.length) {
            this.loading = false
          }
        })
      }
    },
    fileUpload(file) {
      const formdata = new FormData()
      formdata.append('file', file)

      return this.$Files
        .createResource({ body: formdata })
        .then(response => {
          if (response.status === 201) {
            this.$emit('fileUploaded', response.data)
          }
          return response
        })
        .catch(error => {
          if (error.response) {
            this.mutablePreviewImage = null
            this.$helper.showToast(
              this.$toast,
              Toast,
              'Failed to upload file',
              '',
              'danger',
            )
          }
        })
    },
  },
}
</script>
