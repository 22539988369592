var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"courierForm",attrs:{"tag":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('image-upload',{attrs:{"preview-image":_vm.fileURL},on:{"fileUploaded":_vm.setPhoto}}),_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{staticClass:"row",attrs:{"label":"First name","label-for":"h-first-name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"First name","vid":"user.givenName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-first-name","state":errors.length > 0 ? false : null},model:{value:(_vm.user.givenName),callback:function ($$v) {_vm.$set(_vm.user, "givenName", $$v)},expression:"user.givenName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Last name","label-for":"h-last-name","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Last name","vid":"user.familyName"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-last-name","state":errors.length > 0 ? false : null},model:{value:(_vm.user.familyName),callback:function ($$v) {_vm.$set(_vm.user, "familyName", $$v)},expression:"user.familyName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Additional name","label-for":"h-additional-name","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-input',{attrs:{"id":"h-first-name"},model:{value:(_vm.user.additionalName),callback:function ($$v) {_vm.$set(_vm.user, "additionalName", $$v)},expression:"user.additionalName"}})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Phone","label-for":"h-phone","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Phone","vid":"user.telephone"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-phone","state":errors.length > 0 ? false : null},model:{value:(_vm.user.telephone),callback:function ($$v) {_vm.$set(_vm.user, "telephone", $$v)},expression:"user.telephone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Email","label-for":"h-email","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":"Email","vid":"user.email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-email","type":"email","state":errors.length > 0 ? false : null},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),(_vm.requiredRole)?_c('b-form-group',{staticClass:"row",attrs:{"label":"Role","label-for":"h-role","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Role","vid":"user.role"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('custom-select',{attrs:{"options":_vm.roles},model:{value:(_vm.user.role),callback:function ($$v) {_vm.$set(_vm.user, "role", $$v)},expression:"user.role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1323497005)})],1):_vm._e(),_c('b-form-group',{staticClass:"row",attrs:{"label":_vm.requiredPassword ? 'Password' : 'Change password',"label-for":"h-password","label-cols-md":"2","content-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":_vm.requiredPassword ? 'required' : '',"name":"Password","vid":"user.password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"h-password","type":"password","state":errors.length > 0 ? false : null},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"row",attrs:{"label":"Enabled","label-for":"h-active","label-cols-md":"2","content-cols-lg":"4"}},[_c('b-form-checkbox',{attrs:{"id":"h-active","switch":""},model:{value:(_vm.user.enabled),callback:function ($$v) {_vm.$set(_vm.user, "enabled", $$v)},expression:"user.enabled"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }